// var stickyHeader = false;
// var transformHomepageDonationAmountImages = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = true;
var countUpStats = true;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

var lualBodyClasses = ["PostSystemCategory_christmas-appeal-style"];

// Add wrapper to header content
$( ".headerContent" ).wrap( "<div class='headerContentWrapper'></div>" );

// Add back to top button to every page
$('<a class="button backToTop" href="#pageHeader" role="button">Top</a>').appendTo('body');